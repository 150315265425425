import React from "react"
import SearchResultPage from "../../../templates/search-results-template"

const SearchResultResiSales = props => {
  const fullpath = props.location.pathname
  const location = props.location
  const fullpathone = props.location

  return (
    <React.Fragment>
      <SearchResultPage
        locationname="/property/to-rent/"
        pcategorytype={["residential", "long_term", "short_term"]}
        // multipcategorytype = {['residential']}
        ptype="lettings" 
        ptypetag="to-rent" 
        pstatustype={["To Let", "Let Agreed"]}
        fullpathone={fullpathone}
        fullpathname={fullpath}
        {...props}
      />
    </React.Fragment>
  )
}

export default SearchResultResiSales
